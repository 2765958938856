// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Types
import environment from '../../config';
import { RootState } from '../store';
import {
  ICreatePostResponse,
  IGetAllPostsPrivateData,
  IGetAllPostsPrivateResponse,
  IGetPostByIdResponse,
  IUpdatePostData,
  IUpdatePostResponse,
} from './postsTypes';
import { ISimpleMessageResponse } from '../../interfaces/http';

export const postsApi = createApi({
  reducerPath: 'postsApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${environment.BASE_URL}/posts`,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = (getState() as RootState).auth.tokens.accessToken;

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      headers.set('x-api-key', environment.API_KEY);

      return headers;
    },
  }),

  endpoints: builder => ({
    createPost: builder.mutation<ICreatePostResponse, FormData>({
      query: (postData: FormData) => {
        return {
          url: `/`,
          method: 'POST',
          formData: true,
          body: postData,
        };
      },
    }),

    updatePostById: builder.mutation<IUpdatePostResponse, IUpdatePostData>({
      query: ({ postId, postData }) => {
        return {
          url: `/${postId}`,
          method: 'PATCH',
          formData: true,
          body: postData,
        };
      },
    }),

    getAllPostsPrivate: builder.query<
      IGetAllPostsPrivateResponse,
      IGetAllPostsPrivateData
    >({
      query: params => ({
        url: '',
        params,
      }),
    }),

    getPostById: builder.query<IGetPostByIdResponse, string>({
      query: postId => `/${postId}`,
    }),

    deletePost: builder.mutation<ISimpleMessageResponse, string>({
      query: postId => {
        return {
          url: `/${postId}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useCreatePostMutation,
  useUpdatePostByIdMutation,
  useLazyGetAllPostsPrivateQuery,
  useLazyGetPostByIdQuery,
  useDeletePostMutation,
} = postsApi;
