//react
import React, { FC } from 'react';

//styles
import styles from './styles.module.scss';

//mui components
import { Box, Button, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface ConfirmDeclineButtonsProps {
  handleCancel: () => void;
  handleSave?: () => void;
  saveButtonText?: string;
  isLoading: boolean;
}

const ConfirmDeclineButtons: FC<ConfirmDeclineButtonsProps> = ({
  handleCancel,
  handleSave,
  saveButtonText,
  isLoading,
}) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap="6px">
      <Button
        variant={'outlined'}
        className={`${styles.button} ${styles.buttonOutlined}`}
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <LoadingButton
        type="submit"
        variant={'contained'}
        startIcon={isLoading && <CircularProgress size={20} />}
        loadingPosition={isLoading ? 'start' : undefined}
        className={`${styles.button} ${styles.buttonContained}`}
        fullWidth
        onClick={handleSave && handleSave}
        disableElevation
        disabled={isLoading}
      >
        {saveButtonText || 'Save'}
      </LoadingButton>
    </Box>
  );
};

export default ConfirmDeclineButtons;
