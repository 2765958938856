import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUser, UserRoleEnum } from './userTypes';

type UserState = {
  currentUser: IUser;
};

const initialState = {
  currentUser: {
    _id: '',
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    role: UserRoleEnum.USER,
    createdBy: null,
    createdAt: new Date(),
  },
} as UserState;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<IUser>) => {
      state.currentUser = action.payload;
    },
    resetUserData: state => {
      Object.assign(state, initialState);
    },
  },
});

export const { resetUserData, setCurrentUser } = userSlice.actions;

export default userSlice.reducer;
