// Types
import { RootState } from '../store';

const getCurrentUser = (state: RootState) => state.user.currentUser;

const userSelectors = {
  getCurrentUser,
};

export default userSelectors;
