'use client';

// React
import React, { useEffect } from 'react';

// Redux
import { useAppDispatch } from '../store/hooks';
import { logout } from '../store/auth/authSlice';

export function MultiTabLogoutProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Check for changes in 'persist:root' storage
    const handleStorageChange = (e: any) => {
      if (e.key === 'persist:root') {
        // Parse the data from storage
        const parsedData = JSON.parse(e.newValue ?? '{}');
        const auth = parsedData.auth ? JSON.parse(parsedData.auth) : null;
        const tokens = auth ? auth.tokens : null;

        // Log out if tokens are empty in storage
        if (
          !!tokens &&
          tokens.accessToken === '' &&
          tokens.refreshToken === ''
        ) {
          dispatch(logout());
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);

  return <>{children}</>;
}
