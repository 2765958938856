// React imports
import React, { FC, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// Material-UI icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

// Custom utility functions and hooks
import { useUpdateStaticPageByIdMutation } from '../../store/staticPages/staticPagesApi';
import { showErrorNotify } from '../../Components/helpers/showErrorNotify';
import { showSuccessNotify } from '../../Components/helpers/showSuccessNotify';
import { SortOrder } from '../../interfaces/requestParams';
import { staticPagesTabHeaders } from './constants';
import PageCard from '../../Components/PageCard/PageCard';

// Styles
import styles from './styles.module.scss';
import { PageType } from '../../interfaces/page';

interface WorkspaceProps {
  initialItems: PageType[];
  disableDrag?: boolean;
  onDeletePage: (staticPageId: string) => void;
  sortCell: string | null;
  setSortCell?: (cell: string | null) => void;
  sortDirection?: SortOrder;
  setSortDirection: (direction: SortOrder) => void;
  isLoadingDeleteStaticPage: boolean;
}

export const StaticPagesWorkspace: FC<WorkspaceProps> = ({
  initialItems,
  disableDrag,
  onDeletePage,
  sortCell,
  setSortCell,
  sortDirection,
  setSortDirection,
  isLoadingDeleteStaticPage,
}) => {
  const [items, setItems] = useState<PageType[]>(initialItems);

  const [isUpdating, setIsUpdating] = useState(false);

  const workspaceRef = useRef<HTMLDivElement | null>(null);

  const [updateStaticPage, updateStaticPageResponse] =
    useUpdateStaticPageByIdMutation();

  const createSortHandler = (row: string) => {
    if (row === sortCell) {
      setSortDirection(
        sortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
      );
    } else {
      setSortCell && setSortCell(row);
    }
  };

  const renderSortIcon = (column: string) => {
    if (column === sortCell) {
      return sortDirection === SortOrder.ASC ? (
        <ArrowDropUpIcon />
      ) : (
        <ArrowDropDownIcon />
      );
    }
    return null;
  };

  const onDragEnd = async (result: any) => {
    const { destination, source } = result;

    if (!destination || destination.index === source.index) {
      return;
    }

    const reorderedItems = Array.from(items);
    const [reorderedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, reorderedItem);

    setItems(reorderedItems);

    if (!isUpdating) {
      setIsUpdating(true);

      try {
        await handleOrderUpdate(destination.index, reorderedItem._id);
      } catch (error) {
        console.error('Error updating order:', error);
        setItems(items);
        setIsUpdating(false);
      }
    }
  };

  const handleOrderUpdate = async (order: number, pageId: string) => {
    await updateStaticPage({ pageData: { order }, pageId });
  };

  useEffect(() => {
    if (workspaceRef.current) {
      workspaceRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [items]);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = updateStaticPageResponse;
    if (isSuccess && data) {
      showSuccessNotify('Static pages order successfully updated');
    }
    if (isError && error) {
      showErrorNotify(isError, error);
    }
    setIsUpdating(false);
  }, [updateStaticPageResponse]);

  if (disableDrag) {
    return (
      <div className={styles.tableContainer}>
        <div className={styles.headers}>
          {staticPagesTabHeaders.map(header => (
            <div
              key={header.key}
              className={styles.headerCell}
              onClick={() =>
                header.sortable &&
                disableDrag &&
                createSortHandler(header.label)
              }
              style={{
                cursor: header.sortable && disableDrag ? 'pointer' : 'default',
              }}
            >
              {header.label}{' '}
              {disableDrag && header.sortable && renderSortIcon(header.label)}
            </div>
          ))}
        </div>
        <div className={styles.tableBody}>
          {items.map((item, index) => (
            <PageCard
              page={item}
              onDeletePage={onDeletePage}
              disableDrag={disableDrag}
              isLoadingDeleteStaticPage={isLoadingDeleteStaticPage}
            />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.tableContainer}>
        <div className={styles.headers}>
          {staticPagesTabHeaders.map(header => (
            <div
              key={header.key}
              className={styles.headerCell}
              style={{ cursor: 'default' }}
            >
              {header.label}
            </div>
          ))}
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="workspace" direction="vertical">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={styles.tableBody}
              >
                {items.map((item, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <PageCard
                          page={item}
                          onDeletePage={onDeletePage}
                          isLoadingDeleteStaticPage={isLoadingDeleteStaticPage}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
};
