// Notify
import { Notify } from 'notiflix';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { notifyOptions } from './../../Utils/notifyOptions';

export const showErrorNotify = (
  IsError: boolean,
  Error: FetchBaseQueryError | SerializedError | undefined,
) => {
  if (IsError && Error) {
    if ('data' in Error) {
      Notify.failure(
        `${(Error.data as { message: string }).message}`,
        notifyOptions,
      );
    } else {
      Notify.failure(`${Error}`, notifyOptions);
    }
  }
};
