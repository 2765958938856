import { INotifyOptions } from 'notiflix';

export const notifyOptions: INotifyOptions = {
  className: 'notiflix-notify-global',

  clickToClose: true,
  timeout: 3000,

  position: 'right-bottom',
  width: '420px',
  borderRadius: '10px',
  fontFamily: 'Inter',

  success: {
    childClassName: 'notiflix-notify-success',
  },

  failure: {
    childClassName: 'notiflix-notify-failure',
  },
};
