//react
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//mui components
import { Box, Typography } from '@mui/material';

//custom components
import PagesForm from '../../Components/PagesForm/PagesForm';

//redux
import {
  useCreateStaticPageMutation,
  useLazyGetStaticPageByIdQuery,
  useUpdateStaticPageByIdMutation,
} from '../../store/staticPages/staticPagesApi';

//types
import { PageType } from '../../interfaces/page';
import { ICreatePageData } from '../../store/staticPages/staticPagesTypes';

//helpers
import { showErrorNotify } from '../../Components/helpers/showErrorNotify';
import { showSuccessNotify } from '../../Components/helpers/showSuccessNotify';

interface PageCreationProps {
  isEdit?: boolean;
}

export const PageCreation: FC<PageCreationProps> = ({ isEdit = false }) => {
  const [staticPage, setStaticPage] = useState<PageType | null>(null);

  //react-router-dom
  const { id } = useParams();
  const navigate = useNavigate();

  //RTQ Query
  const [createStaticPage, createStaticPageResponse] =
    useCreateStaticPageMutation();

  const [updateStaticPage, updateStaticPageResponse] =
    useUpdateStaticPageByIdMutation();

  const [getStaticPage, getStaticPageResponse] =
    useLazyGetStaticPageByIdQuery();

  //Handlers
  const handleSubmit = (pageData: ICreatePageData) => {
    if (isEdit && id) {
      updateStaticPage({ pageData, pageId: id });
    } else {
      createStaticPage(pageData);
    }
  };

  //useEffect
  useEffect(() => {
    if (isEdit && id) getStaticPage(id);
  }, [isEdit, id, getStaticPage]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = createStaticPageResponse;
    if (data && isSuccess) {
      showSuccessNotify('Page created successfully');
      navigate('/pages');
    }

    showErrorNotify(isError, error);
  }, [createStaticPageResponse]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = updateStaticPageResponse;
    if (data && isSuccess) {
      showSuccessNotify('Page updated successfully');
      navigate('/pages');
    }

    showErrorNotify(isError, error);
  }, [updateStaticPageResponse]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = getStaticPageResponse;
    if (data && isSuccess) {
      setStaticPage(data.data.staticPage);
    }

    showErrorNotify(isError, error);
  }, [getStaticPageResponse]);

  return (
    <Box position="relative" pb="10px">
      <Typography variant="h3">{isEdit ? 'Edit' : 'Create'} page</Typography>

      <PagesForm
        isEdit={isEdit}
        page={staticPage}
        onSubmit={handleSubmit}
        isLoading={
          createStaticPageResponse.isLoading ||
          updateStaticPageResponse.isLoading
        }
      />
    </Box>
  );
};
