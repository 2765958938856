//react
import React, { FC, useEffect, useState } from 'react';

// Redux
import authSelectors from '../../../src/store/auth/authSelectors';
import { useAppSelector } from '../../store/hooks';

//Router
import { useNavigate, useLocation } from 'react-router-dom';

//mui components
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

// constants
import { listItem, menuList, menuTitle } from './constants';

//icons
import FunctionLineIcon from 'remixicon-react/FunctionLineIcon';
import FileEditLineIcon from 'remixicon-react/FileEditLineIcon';
import FileCopy2LineIcon from 'remixicon-react/FileCopy2LineIcon';
import GroupLineIcon from 'remixicon-react/GroupLineIcon';

//styles
import styles from './styles.module.scss';

// Types
import { UserRoleEnum } from '../../store/user/userTypes';

const drawerWidth = 281;

interface AppSidebarProps {
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
  closeDrawer: () => void;
}

const AppSidebar: FC<AppSidebarProps> = ({
  handleDrawerToggle,
  mobileOpen,
  closeDrawer,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = useAppSelector(authSelectors.getUserRole);

  const [container, setContainer] = useState<HTMLElement | undefined>(
    undefined
  );
  const [links, setLinks] = useState<listItem[] | []>([]);

  useEffect(() => {
    setLinks(modifyMenuForRole(menuList));
  }, []);

  const handleClick = () => {
    closeDrawer();
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const value =
        window !== undefined ? () => window.document.body : undefined;
      setContainer(value);
    }
  }, []);

  const viewIcon = (item: listItem) => {
    switch (item.text) {
      case menuTitle.DASHBOARD:
        return <FunctionLineIcon />;
      case menuTitle.POSTS:
        return <FileEditLineIcon />;
      case menuTitle.PAGES:
        return <FileCopy2LineIcon />;
      case menuTitle.USERS:
        return <GroupLineIcon />;

      default:
        break;
    }
  };

  const modifyMenuForRole = (menuList: listItem[]) => {
    return menuList;
  };

  const drawer = (
    <Box className={styles.nav}>
      <Typography
        variant="h6"
        noWrap
        component="div"
        color="blue"
        className={styles.logo}
        sx={{ display: { xs: 'flex', md: 'none' } }}
      >
        CMS
      </Typography>
      <Box>
        <List>
          {links.map(item => {
            if (
              item.text === menuTitle.USERS &&
              userRole !== UserRoleEnum.SUPER_ADMIN
            ) {
              return null;
            }

            return (
              <React.Fragment key={item.text}>
                <div onClick={() => navigate(item.path)}>
                  <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      className={
                        item.activeRoutes.some(route => {
                          const regex = new RegExp(
                            `^${route.replace(/:[^/]+/g, '[^/]+')}$`
                          );
                          return regex.test(location.pathname);
                        })
                          ? styles.active
                          : ''
                      }
                      sx={{
                        minHeight: 48,
                        px: 2.5,
                        paddingLeft: '30px',
                        paddingRight: '22px',
                      }}
                      onClick={handleClick}
                    >
                      <ListItemIcon
                        sx={{ minWidth: '0px', marginRight: '19px' }}
                        className={styles.icon}
                      >
                        {viewIcon(item)}
                      </ListItemIcon>
                      <ListItemText>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            className={styles.text}
                            variant="subtitle1"
                          >
                            {item.text}
                          </Typography>
                        </Box>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </div>
                {!!item.children && (
                  <List
                    className={styles.nav__sub}
                    component="div"
                    disablePadding
                  >
                    {item.children.map((subitem, index) => (
                      <div key={index} onClick={() => navigate(subitem.path)}>
                        <ListItemButton
                          className={
                            location.pathname === subitem.path
                              ? styles.activeChildren
                              : ''
                          }
                          sx={{
                            minHeight: 48,
                            justifyContent: mobileOpen ? 'initial' : 'center',
                            px: 2.5,
                            py: '4px',
                            pl: '74px',
                          }}
                        >
                          <ListItemText>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography className={styles.textSub}>
                                {subitem.text}
                              </Typography>
                            </Box>
                          </ListItemText>
                        </ListItemButton>
                      </div>
                    ))}
                  </List>
                )}
              </React.Fragment>
            );
          })}
        </List>
      </Box>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { lg: drawerWidth },
        flexShrink: { sm: 0 },
        minHeight: 'calc(100vh - 67px)',
        backgroundColor: '#fff',
      }}
      aria-label="navbar"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            border: 'none',
            position: 'relative',
            height: '100%',
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            border: 'none',
            position: 'relative',
            height: '100%',
          },
        }}
        open={true}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default AppSidebar;
