import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../store/hooks';

export default function PublicRoute({ redirectTo, children }) {
  const location = useLocation();

  const isAuth = useAuth();

  return !isAuth ? (
    children
  ) : (
    <Navigate to={location.state ? location.state.from : redirectTo} />
  );
}
