// React
import React, { FormEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Mui
import { TextField, Typography, Box, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Icon
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import MailCheckLineIcon from 'remixicon-react/MailCheckLineIcon';

// Styles
import s from './styles.module.scss';

//redux
import { useResetPasswordSendEmailMutation } from '../../../store/auth/authApi';

// Notification
import { showErrorNotify } from '../../helpers/showErrorNotify';
import { showSuccessNotify } from '../../helpers/showSuccessNotify';

const ResetPasswordSendEmailForm = () => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const [
    resetPasswordSendEmailRequest,
    { data, isSuccess, isError, error, isLoading },
  ] = useResetPasswordSendEmailMutation();

  // Handlers
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    resetPasswordSendEmailRequest(formData);
  };

  useEffect(() => {
    if (data && isSuccess) {
      showSuccessNotify(data.message);
      setFormData({
        email: '',
      });
    }

    showErrorNotify(isError, error);
  }, [data, isSuccess, error, isError]);

  return (
    <>
      {isSuccess ? (
        <div className={s.container}>
          <MailCheckLineIcon className={s.successIcon} />
          <Box>
            <Typography className={s.successTitle} variant={'h1'}>
              Password Reset
            </Typography>
            <Typography className={s.successContent}>
              The password reset link has been sent to your email
            </Typography>
          </Box>
        </div>
      ) : (
        <form className={s.form} onSubmit={e => handleSubmit(e)}>
          <Typography className={s.formTitle} variant={'h1'}>
            Forgot Your Password ?
          </Typography>

          <Box>
            <Typography className={s.label}>Email</Typography>
            <TextField
              className={s.input}
              variant="outlined"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
            />
          </Box>

          <Typography className={s.formInfo}>
            Enter your email to recover your password
          </Typography>

          <LoadingButton
            type="submit"
            startIcon={isLoading && <CircularProgress size={20} />}
            loadingPosition={isLoading ? 'start' : undefined}
            className={s.button}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            disabled={isLoading}
          >
            Reset
          </LoadingButton>

          <Link className={s.formLink} to={'/login'}>
            <ArrowLeftSLineIcon />
            <Typography className={s.formLinkDesc}>Back to sign in</Typography>
          </Link>
        </form>
      )}
    </>
  );
};

export default ResetPasswordSendEmailForm;
