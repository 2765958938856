import React, { FC } from 'react';

//mui components
import {
  Button,
  Grid,
  Modal,
  Typography,
  IconButton,
  Box,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

//styles
import styles from './styles.module.scss';

// remixicon
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

interface PagesCategoryModalProps {
  open: boolean;
  onClose: () => void;
  pageTitle: string;
  onDelete: () => void;
  isLoadingDeleteStaticPage: boolean;
}

const DeletePageModal: FC<PagesCategoryModalProps> = ({
  open,
  onClose,
  pageTitle,
  onDelete,
  isLoadingDeleteStaticPage,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ backdropFilter: 'blur(10px)' }}
    >
      <Grid className={styles.modal}>
        <Typography className={styles.modalTitle} variant="h6">
          {`Please confirm if you want to permanently remove the page ${pageTitle}. This action is irreversible.`}
        </Typography>
        <Box
          display={'flex'}
          justifyContent={'center'}
          gap={'10px'}
          width={'100%'}
        >
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            className={`${styles.button} ${styles.buttonCancel}`}
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            startIcon={
              isLoadingDeleteStaticPage && (
                <CircularProgress
                  sx={{ color: 'var(--neutral-white)' }}
                  size={'16px'}
                />
              )
            }
            loadingPosition={isLoadingDeleteStaticPage ? 'start' : undefined}
            className={styles.button}
            onClick={onDelete}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            disabled={isLoadingDeleteStaticPage}
          >
            Delete
          </LoadingButton>
        </Box>

        <IconButton className={styles.buttonClose} onClick={onClose}>
          <CloseLineIcon />
        </IconButton>
      </Grid>
    </Modal>
  );
};

export default DeletePageModal;
