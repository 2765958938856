// Components
import UpdatePasswordForm from '../../../Components/ResetPassword/UpdatePasswordForm/UpdatePasswordForm';

// Styles
import s from './styles.module.scss';

export const UpdatePasswordPage = () => {
  return (
    <div className={s.formWrapper}>
      <UpdatePasswordForm />
    </div>
  );
};
