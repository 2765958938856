import { IResponse } from "../../interfaces/http";

// GetAllMetrics
export interface IGetAllMetricsResponse extends IResponse<{
  metrics: {
    postsMetrics: {
      [key in PostMetricEnum]: MetricType;
    }
  }
}> { }

export type MetricType = {
  isActive: number;
  total: number;
}

export enum PostMetricEnum {
  BLOG = 'typeBlog',
  // NEWS = 'typeNews',
  HOT_TECH = 'typeHotTech',
  SPOT_LIGHT = 'typeSpotlight',
  ALL_POSTS = 'allPosts',
}

export type PostMetricType = 'typeBlog' | 'typeNews' | 'typeHotTech' | 'typeSpotlight' | 'allPosts';