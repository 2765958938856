import React from 'react';
import { CircularProgress } from '@mui/material';

interface IProps {
  color: string;
  size: number | string;
}

const Loader = ({ color, size }: IProps) => {
  return <CircularProgress sx={{ color: color }} size={size} />;
};

export default Loader;
