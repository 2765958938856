// Components
import LoginForm from '../../../Components/Login/LoginForm/LoginForm';

// Styles
import s from './styles.module.scss';

export const LoginPage = () => {
  return (
    <div className={s.formWrapper}>
      <LoginForm />
    </div>
  );
};

// export default LoginPage;
