import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILoginResponse, IRefreshResponse } from './authTypes';
import { UserRoleEnum } from '../user/userTypes';

type AuthState = {
  user: { _id: string; role: UserRoleEnum | null };
  tokens: { accessToken: string; refreshToken: string };
};

const initialState = {
  user: { _id: '', role: null },
  tokens: { accessToken: '', refreshToken: '' },
} as AuthState;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginData: (state, action: PayloadAction<ILoginResponse>) => {
      const { tokens, user } = action.payload.data;

      state.user = user;
      state.tokens.accessToken = tokens.accessToken;
      state.tokens.refreshToken = tokens.refreshToken;
    },
    logout: state => {
      Object.assign(state, initialState);
    },
    setRefreshData: (state, action: PayloadAction<IRefreshResponse>) => {
      state.tokens.accessToken = action.payload.accessToken;
      state.tokens.refreshToken = action.payload.refreshToken;
    },
  },
});

export const { setLoginData, logout, setRefreshData } = authSlice.actions;

export default authSlice.reducer;
