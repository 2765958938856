//react
import React, { FC, useEffect, useState } from 'react';

//Router
import { useNavigate } from 'react-router-dom';

//styles
import styles from './index.module.scss';

//mui components
import { Box, Typography, IconButton } from '@mui/material';

//remixicon
import More2Line from 'remixicon-react/More2LineIcon';
import Image2Fill from 'remixicon-react/Image2FillIcon';
import TimeLine from 'remixicon-react/TimeLineIcon';

//types
import { PostType } from '../../interfaces/post';
import { PostTypeEnum } from '../../interfaces/requestParams';

//custom components
import PostCardMenu from '../PostCardMenu';
import DeletePostModal from '../Modals/DeletePostModal/DeletePostModal';

//helpers
import { formatPostDate } from '../../helpers';

interface PostCardProps {
  post: PostType;
  deletePost: () => void;
  isLoadingDeletePost: boolean;
}

const PostCard: FC<PostCardProps> = ({
  post,
  deletePost,
  isLoadingDeletePost,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeletePostModal, setOpenDeletePostModal] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const {
    title,
    isActive,
    _id,
    mainImage,
    type,
    user,
    updatedBy,
    contentUpdatedAt,
    createdAt,
  } = post;

  const userName = updatedBy
    ? `${updatedBy?.first_name} ${updatedBy?.last_name}`
    : `${user?.first_name} ${user?.last_name}`;

  const handleClickSubmenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleScrollMenu = () => {
    handleCloseMenu();
  };

  useEffect(() => {
    if (anchorEl) {
      window.addEventListener('scroll', handleScrollMenu);
    } else {
      window.removeEventListener('scroll', handleScrollMenu);
    }
  }, [anchorEl]);

  return (
    <Box
      className={styles.postContainer}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {mainImage ? (
        <img src={mainImage} alt="PreviewImage" className={styles.postImage} />
      ) : (
        <Box
          className={styles.postImage}
          textAlign="center"
          alignContent="center"
        >
          <Image2Fill style={{ width: '50px', height: '50px', fill: '#bbb' }} />
        </Box>
      )}
      <Box>
        <Typography className={styles.postType}>
          {type === PostTypeEnum.BLOG ? 'Articles' : type}
        </Typography>
        <Typography
          className={`${styles.postTitle} ${styles.postTitleHover}`}
          onClick={() => navigate(`/post/${_id}`)}
        >
          {title}
        </Typography>
        <Typography className={styles.postCreatedBy}>
          {updatedBy ? 'Updated By' : 'Created By'}: {userName}
        </Typography>
        <Typography
          className={styles.postUpdatedAt}
          display="flex"
          alignItems="center"
        >
          <TimeLine
            style={{
              width: '14px',
              height: '14px',
              fill: '#5d6b82',
              marginRight: '4px',
            }}
          />
          {formatPostDate(contentUpdatedAt || createdAt)}
        </Typography>
      </Box>
      {isActive && <span className={styles.postActiveLabel}>Active</span>}
      <IconButton
        className={styles.postMoreButton}
        onClick={handleClickSubmenu}
      >
        <More2Line />
      </IconButton>
      <PostCardMenu
        anchorEl={anchorEl}
        handleClose={handleCloseMenu}
        handleDelete={() => setOpenDeletePostModal(true)}
        handleEdit={() => navigate(`/post-edit/${_id}`)}
      />

      <DeletePostModal
        open={openDeletePostModal}
        onClose={() => setOpenDeletePostModal(false)}
        onDelete={deletePost}
        isLoadingDeletePost={isLoadingDeletePost}
      />
    </Box>
  );
};

export default PostCard;
