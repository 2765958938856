import { IPaginationParams, IPaginationResponse } from '@/interfaces/http';

// User Role
export enum UserRoleEnum {
  SUPER_ADMIN = 'SUPER_ADMIN',
  USER = 'USER',
}

export interface IUserCreator {
  _id: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

export interface IUser {
  _id: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  role: UserRoleEnum;
  createdBy: IUserCreator | null;
  createdAt: Date;
}

// GET ALL USERS
export interface IGetAllUsersResponse
  extends IPaginationResponse<{
    users: IUser[];
  }> {
  totalUsers: number;
}

export interface IGetAllUsersRequest extends IPaginationParams {}

// CREATE USER
export interface ICreateUserRequest {
  username: string;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
}

export interface ICreateUserResponse {
  data: {
    createdUser: IUser;
  };
  message: string;
}

// GET USER BY ID
export interface IGetUserByIdResponse {
  data: {
    user: IUser;
  };
}
