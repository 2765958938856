import React from 'react';

// MUI
import { Pagination } from '@mui/material';

// Styles
import s from './styles.module.scss';

const PaginationPanel = ({
  totalPages,
  currentPage,
  setPage,
}: {
  totalPages: number;
  currentPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  // Handlers
  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  return (
    <>
      <Pagination
        className={s.pagination}
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
      />
    </>
  );
};

export default PaginationPanel;
