// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Types
import environment from '../../config';
import { RootState } from '../store';
import { IGetAllMetricsResponse } from './metricsTypes';

export const metricsApi = createApi({
  reducerPath: 'metricsApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${environment.BASE_URL}/metrics`,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = (getState() as RootState).auth.tokens.accessToken;

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      headers.set('x-api-key', environment.API_KEY);

      return headers;
    },
  }),

  endpoints: builder => ({
    getAllMetrics: builder.query<IGetAllMetricsResponse, void>({
      query: () => '/',
    }),
  }),
});

export const { useLazyGetAllMetricsQuery } = metricsApi;
