import React, { useEffect, useState } from 'react';

// mui components
import { IconButton, TableCell, TableRow } from '@mui/material';

// icons
import More2FillIcon from 'remixicon-react/More2FillIcon';

// styles
import styles from './styles.module.scss';

// helpers
import { formatResponseDate } from '../../Utils/formatResposnseDate';

// Types
import { IUser, UserRoleEnum } from '../../store/user/userTypes';
import UserCardMenu from '../UserCardMenu/UserCardMenu';

interface IProps {
  user: IUser;
  handleGetAllUsers: () => void;
}

const UserCard = ({ user, handleGetAllUsers }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickSubmenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleScrollMenu = () => {
    handleCloseMenu();
  };

  useEffect(() => {
    if (anchorEl) {
      window.addEventListener('scroll', handleScrollMenu);
    } else {
      window.removeEventListener('scroll', handleScrollMenu);
    }
  }, [anchorEl]);
  return (
    <TableRow className={styles.row}>
      <TableCell>{user.username}</TableCell>

      <TableCell>{user.first_name}</TableCell>
      <TableCell>{user.last_name}</TableCell>

      <TableCell>
        {user.role === UserRoleEnum.SUPER_ADMIN
          ? '-'
          : `${user.createdBy?.first_name || 'Unknown user'} ${
              user.createdBy?.last_name
            }`}
      </TableCell>

      <TableCell>{formatResponseDate(user.createdAt)}</TableCell>

      <TableCell>{user.email}</TableCell>

      <TableCell>
        {user.role === UserRoleEnum.SUPER_ADMIN ? (
          ''
        ) : (
          <>
            <IconButton
              className={styles.buttonMenu}
              onClick={handleClickSubmenu}
            >
              <More2FillIcon />
            </IconButton>
            <UserCardMenu
              anchorEl={anchorEl}
              handleClose={handleCloseMenu}
              handleGetAllUsers={handleGetAllUsers}
              user={user}
            />
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default UserCard;
