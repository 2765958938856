import React, { FC } from 'react';

// components
import { Menu, MenuItem, Typography } from '@mui/material';

//styles
import styles from './styles.module.scss';

interface PCardMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handleDelete: () => void;
  handleEdit: () => void;
}

interface IMenuList {
  text: string;
  icon: React.ReactNode | null;
  action: () => void;
  hide: boolean;
}

const PostCardMenu: FC<PCardMenuProps> = ({
  anchorEl,
  handleClose,
  handleDelete,
  handleEdit,
}) => {
  let menuList: IMenuList[] = [
    {
      text: 'Edit',
      icon: null,
      action: handleEdit,
      hide: false,
    },
    {
      text: 'Delete',
      icon: null,
      action: handleDelete,
      hide: false,
    },
  ];

  return (
    <Menu
      className={styles.postCardMenu}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          borderRadius: '8px',
          width: 'fitContent',
          minWidth: '120px',
        },
      }}
    >
      {menuList.map(({ text, icon, action, hide }, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            action();
            handleClose();
          }}
          sx={{
            display: hide ? 'none' : 'flex',
          }}
          className={styles.postCardMenuItem}
        >
          {icon && icon}
          <Typography className={styles.postCardMenuText} variant="h6">
            {text}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default PostCardMenu;
