import React, { FC, useState } from 'react';

// MUI
import { Menu, MenuItem, Typography } from '@mui/material';

// components
import UserDeleteModal from '../Modals/UserDeleteModal/UserDeleteModal';

//styles
import styles from './styles.module.scss';

// Types
import { IUser } from '../../store/user/userTypes';

interface ICardMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  user: IUser;
  handleGetAllUsers: () => void;
}

interface IMenuList {
  text: string;
  icon: React.ReactNode | null;
  action: () => void;
  hide: boolean;
}

const UserCardMenu: FC<ICardMenuProps> = ({
  handleGetAllUsers,
  user,
  anchorEl,
  handleClose,
}) => {
  const [isDeleteUserModalOpen, setDeleteUserModalOpen] =
    useState<boolean>(false);
  let menuList: IMenuList[] = [
    {
      text: 'Delete',
      icon: null,
      action: () => setDeleteUserModalOpen(true),
      hide: false,
    },
  ];

  return (
    <>
      <Menu
        className={styles.postCardMenu}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            borderRadius: '8px',
            width: 'fitContent',
            minWidth: '120px',
          },
        }}
      >
        {menuList.map(({ text, icon, action, hide }, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              action();
              handleClose();
            }}
            sx={{
              display: hide ? 'none' : 'flex',
            }}
            className={styles.postCardMenuItem}
          >
            {icon && icon}
            <Typography className={styles.postCardMenuText} variant="h6">
              {text}
            </Typography>
          </MenuItem>
        ))}
      </Menu>

      <UserDeleteModal
        isOpen={isDeleteUserModalOpen}
        onClose={() => setDeleteUserModalOpen(false)}
        user={user}
        handleGetAllUsers={handleGetAllUsers}
      />
    </>
  );
};

export default UserCardMenu;
