export const navigationsTypes = [
  {
    title: "Page",
    id: "page",
  },
  {
    title: "Custom URL",
    id: "custom_url",
  },
  {
    title: "Category",
    id: "category",
  },
];

export const initStateNavigations = {
  title: "",
  item_type: "category",
  parent_id: null,
};

export const addCategoryConstants = {
  minLength: 2,
  maxLength: 30,
  categoriesLimit: 6,
};
