import React, { useEffect, useRef } from 'react';
import Loader from '../common/Loader';
import { Typography } from '@mui/material';

interface InfinityScrollWrapperProps {
  children: React.ReactNode;
  nextPage: number;
  onBottomReach: () => void;
  isLoader?: boolean;
  additionConditions?: boolean;
  onEndScrollingMessage?: string;
  marginEndMessage?: string;
}

export const InfinityScrollWrapper = ({
  children,
  nextPage,
  onBottomReach,
  isLoader = false,
  additionConditions = true,
  onEndScrollingMessage,
  marginEndMessage = '',
}: InfinityScrollWrapperProps) => {
  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && additionConditions) {
          onBottomReach();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(observerTarget.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observerTarget, nextPage]);

  return (
    <div style={{ width: '100%' }}>
      {children}
      <div id="infinityScrollObserverTarget" ref={observerTarget}></div>
      <div
        style={{
          display: !!isLoader || !!onEndScrollingMessage ? 'flex' : 'none',
          justifyContent: 'center',
          margin: !!onEndScrollingMessage
            ? (!!marginEndMessage && marginEndMessage) || '0 0 20px 0'
            : '0px',
        }}
      >
        {isLoader && !onEndScrollingMessage && (
          <Loader size={50} color={'#000000'} />
        )}
        {onEndScrollingMessage && (
          <Typography mt={2}>{onEndScrollingMessage}</Typography>
        )}
      </div>
    </div>
  );
};
