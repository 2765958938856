// react
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//custom components
import DeletePageModal from '../Modals/DeletePageModal/DeletePageModal';

// icons
import EditFillIcon from 'remixicon-react/EditFillIcon';
import DeleteBin5FillIcon from 'remixicon-react/DeleteBin5FillIcon';

// styles
import styles from './styles.module.scss';

// types
import { PageType } from '../../interfaces/page';

// helpers
import { formatResponseDate } from '../../Utils';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface IProps {
  page: PageType;
  onDeletePage: (staticPageId: string) => void;
  disableDrag?: boolean;
  isLoadingDeleteStaticPage: boolean;
}

const PageCard: FC<IProps> = ({
  page,
  onDeletePage,
  disableDrag,
  isLoadingDeleteStaticPage,
}) => {
  const navigate = useNavigate();

  const [deletePageModal, setDeletePageModal] = useState<boolean>(false);
  const onDeletePageHandler = () => {
    onDeletePage(page._id);
    setDeletePageModal(false);
  };

  return (
    <div className={styles.row}>
      <div className={styles.cell}>
        {!disableDrag ? <DragIndicatorIcon /> : ''}
      </div>
      <div className={styles.cell}>{page.title}</div>
      <div className={styles.cell}>
        {page.user?.first_name || 'Unknown user'} {page.user?.last_name}
      </div>
      <div className={styles.cell}>{formatResponseDate(page.createdAt)}</div>
      <div className={styles.cell}>
        {page.category ? page.category.title : 'No category'}
      </div>
      <div className={styles.cell}>
        {page.isActive ? 'Active' : 'Not active'}
      </div>
      <div className={styles.cell}>
        <button
          className={styles.buttonMenu}
          onClick={() => navigate(`/page-edit/${page._id}`)}
          style={{ marginRight: '2px' }}
        >
          <EditFillIcon />
        </button>
        <button
          className={styles.buttonMenu}
          style={{ marginLeft: 12 }}
          onClick={() => setDeletePageModal(true)}
        >
          <DeleteBin5FillIcon />
        </button>
      </div>
      <DeletePageModal
        open={deletePageModal}
        onClose={() => setDeletePageModal(false)}
        onDelete={onDeletePageHandler}
        pageTitle={page.title}
        isLoadingDeleteStaticPage={isLoadingDeleteStaticPage}
      />
    </div>
  );
};

export default PageCard;
