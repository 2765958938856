import React, { FC, useEffect, useState } from 'react';

//mui components
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
  IconButton,
} from '@mui/material';

//styles
import styles from './styles.module.scss';

//constants
import { addCategoryConstants } from '../../../Utils/constants';

// remixicon
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

interface PagesCategoryModalProps {
  open: boolean;
  onClose: () => void;
  categoryValue?: string;
  onSubmit: (title: string) => void;
}

const PagesCategoryModal: FC<PagesCategoryModalProps> = ({
  open,
  onClose,
  categoryValue,
  onSubmit,
}) => {
  const [category, setCategory] = useState<string>('');

  const handleClose = () => {
    setCategory('');
    onClose();
  };

  const handleSave = () => {
    onSubmit(category);
    handleClose();
  };

  useEffect(() => {
    if (categoryValue && categoryValue.length) {
      setCategory(categoryValue);
    }
  }, [categoryValue]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ backdropFilter: 'blur(10px)' }}
    >
      <Grid className={styles.modal}>
        <Typography className={styles.modalTitle} variant="h6">
          {categoryValue && categoryValue.length ? 'Edit' : 'Add'} Category
        </Typography>
        <Typography className={styles.modalDesc}>
          Organize Content with Categories
        </Typography>
        <Box width="100%" mt="40px">
          <InputLabel className={styles.label}>Category</InputLabel>
          <OutlinedInput
            value={category}
            onChange={e => setCategory(e.target.value)}
            placeholder=""
            fullWidth
            className={styles.input}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={styles.button}
          onClick={handleSave}
          disabled={
            category.length < addCategoryConstants.minLength ||
            category.length > addCategoryConstants.maxLength
          }
        >
          Save
        </Button>

        <IconButton className={styles.buttonClose} onClick={handleClose}>
          <CloseLineIcon />
        </IconButton>
      </Grid>
    </Modal>
  );
};

export default PagesCategoryModal;
