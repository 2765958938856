import React, { FC, ReactNode, useEffect, useRef } from 'react';

// mui components
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

// types
import { PageType } from '../../interfaces/page';
import { SortOrder } from '../../interfaces/requestParams';

// styles
import styles from './styles.module.scss';
import { IUser } from '@/store/user/userTypes';

interface WorkspaceProps {
  items: PageType[] | IUser[];
  rows: string[];
  children: ReactNode;
  sortCell: string | null;
  setSortCell?: (cell: string | null) => void;
  sortDirection?: SortOrder;
  setSortDirection: (direction: SortOrder) => void;
}

const Workspace: FC<WorkspaceProps> = ({
  items,
  rows,
  children,
  sortCell,
  setSortCell,
  sortDirection,
  setSortDirection,
}) => {
  const workspaceRef = useRef<HTMLDivElement | null>(null);

  const createSortHandler = (row: string) => {
    if (row === sortCell) {
      setSortDirection(
        sortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
      );
    } else {
      setSortCell && setSortCell(row);
    }
  };

  useEffect(() => {
    if (workspaceRef.current) {
      workspaceRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [items]);

  useEffect(() => {
    if (workspaceRef.current) {
      workspaceRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [items]);

  return (
    <TableContainer component={Grid} className={styles.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow className={styles.row}>
            {rows.map(row => (
              <TableCell key={row}>
                {row.length ? (
                  <TableSortLabel
                    active={sortCell === row}
                    direction={sortDirection}
                    onClick={() => createSortHandler(row)}
                  >
                    {row}
                  </TableSortLabel>
                ) : (
                  row
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default Workspace;
