import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import authSelectors from '../store/auth/authSelectors';
import { UserRoleEnum } from '../store/user/userTypes';

export default function SuperAdminRoute({ redirectTo, children }) {
  const location = useLocation();
  const userRole = useAppSelector(authSelectors.getUserRole);
  const isSuperAdmin = userRole === UserRoleEnum.SUPER_ADMIN;

  return isSuperAdmin ? (
    children
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
}
