//react
import React from 'react';
import { FC, useEffect, useState } from 'react';

//styles
import styles from '../index.module.scss';

//mui components
import { Box, Typography, IconButton } from '@mui/material';

//remixicon
import More2Line from 'remixicon-react/More2LineIcon';

//custom components
import PostCardMenu from '../../PostCardMenu';
import { ImageLabelTheme } from '../../../interfaces/requestParams';

interface PostImagePreviewProps {
  imageSrc: string;
  imageName: string;
  imageLabel: string;
  imageLabelTheme: string;
  handleDelete: () => void;
  handleEdit: () => void;
}
const PostImagePreview: FC<PostImagePreviewProps> = ({
  imageSrc,
  imageName,
  handleDelete,
  handleEdit,
  imageLabel,
  imageLabelTheme
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickSubmenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleScrollMenu = () => {
    handleCloseMenu();
  };

  useEffect(() => {
    if (anchorEl) {
      window.addEventListener('scroll', handleScrollMenu);
    } else {
      window.removeEventListener('scroll', handleScrollMenu);
    }
  }, [anchorEl]);

  return (
    <Box
      className={styles.postContainer}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box style={{ margin: '0 auto 6px auto'}}>
        <Box position="relative">
          <img
            src={imageSrc}
            alt="PreviewImage"
            className={styles.postImage}
            style={{ marginBottom: imageName ? '12px' : '0px' }}
          />
          { imageLabel &&
            <Box
              className={styles.postLabelContainer}
              style={{
                backgroundColor: imageLabelTheme === ImageLabelTheme.DARK ? 'rgba(0, 0, 0, 0.35)' : 'rgba(255, 255, 255, 0.35)'
              }}
            >
              <Typography className={styles.postLabelText}>{imageLabel}</Typography>
            </Box>
          }
        </Box>
      </Box>

      {imageName && (
        <Typography
          className={styles.postTitle}
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {imageName}
        </Typography>
      )}
      <IconButton
        className={styles.postMoreButton}
        onClick={handleClickSubmenu}
      >
        <More2Line />
      </IconButton>
      <PostCardMenu
        anchorEl={anchorEl}
        handleClose={handleCloseMenu}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
    </Box>
  );
};

export default PostImagePreview;
