//react
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router';

//styles
import styles from './index.module.scss';

//mui
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Avatar,
} from '@mui/material';

//redux
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logout } from '../../store/auth/authSlice';
import { useLazyGetUserByIdQuery } from '../../store/user/userApi';
import authSelectors from '../../store/auth/authSelectors';
import { setCurrentUser } from '../../store/user/userSlice';
import userSelectors from '../../store/user/userSelectors';

// Components
import ProfileMenu from '../ProfileMenu';
import AppSidebar from '../AppSidebar';

//remixicon
import Menu2FillIcon from 'remixicon-react/Menu2FillIcon';

const Header = () => {
  const [profileMenuOpen, setProfileMenuOpen] = useState<null | HTMLElement>(
    null
  );
  const [mobileOpen, setMobileOpen] = useState(false);
  const [getUserById, getUserByIdData] = useLazyGetUserByIdQuery();

  const currentUserId = useAppSelector(authSelectors.getUserId);
  const currentUser = useAppSelector(userSelectors.getCurrentUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getUserById(currentUserId);
  }, [currentUserId]);

  useEffect(() => {
    const { data, isSuccess } = getUserByIdData;
    if (data && isSuccess) {
      dispatch(setCurrentUser(data.data.user));
    }
  }, [getUserByIdData]);

  // Handlers
  const handleProfileMenuClose = () => setProfileMenuOpen(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setProfileMenuOpen(event.currentTarget);

  const handleMenuItemClick = (menuItem: string) => {
    setProfileMenuOpen(null);

    switch (menuItem) {
      case 'Log Out': {
        dispatch(logout());
        return;
      }
      default:
        return;
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  return (
    <Box>
      <AppBar
        position="static"
        sx={{ backgroundColor: '#fff', boxShadow: 'none' }}
      >
        <Toolbar className={styles.header}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            color="blue"
            className={styles.headerLogo}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            CMS
          </Typography>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <Menu2FillIcon color="#0055ff" />
          </IconButton>

          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            sx={{
              '&:hover': { backgroundColor: 'transparent' },
              position: 'relative',
              right: '10px',
              padding: '0px',
            }}
          >
            <Avatar
              alt={currentUser.first_name || 'User'}
              src={'userAvatar'}
              className={styles.headerUserIcon}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box display="flex">
        <AppSidebar
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          closeDrawer={closeDrawer}
        />
        <Box className={styles.outletContainer}>
          <Outlet />
        </Box>
      </Box>

      <ProfileMenu
        anchorEl={profileMenuOpen}
        closeMenu={handleProfileMenuClose}
        onMenuItemClick={handleMenuItemClick}
      />
    </Box>
  );
};

export default Header;
