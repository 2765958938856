// import dayjs
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);

export const formatPostDate = (date: string) => {
  const now = dayjs();
  const postDate = dayjs(date);
  if (now.isSameOrAfter(postDate.add(1, 'day'))) {
    return postDate.format('MM/DD/YYYY');
  } else {
    return postDate.fromNow();
  }
};
