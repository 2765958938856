// Types
import { RootState } from '../store';

const getAccessToken = (state: RootState) => state.auth.tokens.accessToken;
const getRefreshToken = (state: RootState) => state.auth.tokens.refreshToken;
const getUserId = (state: RootState) => state.auth.user._id;
const getUserRole = (state: RootState) => state.auth.user.role;

const authSelectors = {
  getAccessToken,
  getRefreshToken,
  getUserId,
  getUserRole,
};

export default authSelectors;
