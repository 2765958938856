import React, { FC } from 'react';

//mui components
import { Box, Dialog, IconButton, Typography } from '@mui/material';

//helpers
import { useLockBodyScroll } from './../../../helpers';

//mui icons
import { Close } from '@mui/icons-material';

//styles
import styles from './styles.module.scss';

interface IModalProps {
  open: boolean;
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title?: string;
  children: React.ReactNode;
  maxWidth?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | { xs?: string; sm?: string; md?: string; lg?: string; xl?: string };
  modalBodyClassName?: string;
  closeButtonClassName?: string;
  modalTitleClassName?: string;
  modalHeaderClassName?: string;
}
const defaultMaxWidths = {
  xs: 'calc(100vw - 40px)',
  md: '545px',
  xl: '644px',
};

const ModalWrapper: FC<IModalProps> = ({
  open,
  onClose,
  title,
  maxWidth,
  modalBodyClassName,
  children,
  closeButtonClassName,
  modalTitleClassName,
  modalHeaderClassName,
}) => {
  useLockBodyScroll(open);

  const resolvedMaxWidth =
    typeof maxWidth === 'object'
      ? { ...defaultMaxWidths, ...maxWidth }
      : maxWidth;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableScrollLock
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '15px',
          maxWidth: maxWidth ? resolvedMaxWidth : defaultMaxWidths,
        },
      }}
    >
      <Box className={`${styles.modalHeader} ${modalHeaderClassName}`}>
        <IconButton
          onClick={e => onClose(e)}
          className={`${styles.btnClose} ${closeButtonClassName}`}
        >
          <Close />
        </IconButton>
        {!!title && (
          <Typography
            variant="h6"
            className={`${styles.modalTitle} ${modalTitleClassName}`}
          >
            {title}
          </Typography>
        )}
      </Box>
      <Box className={`${styles.modalBody} ${modalBodyClassName}`}>
        {children}
      </Box>
    </Dialog>
  );
};

export default ModalWrapper;
