// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Types
import { ICreatePageData, ICreatePageResponse, IGetAllPagesParamsPrivate, IGetAllPagesPrivateResponse, IGetStaticPageById, IUpdatePageData, IUpdatePageResponse } from './staticPagesTypes';
import environment from '../../config';
import { RootState } from '../store';
import { ISimpleMessageResponse } from '../../interfaces/http';

export const staticPagesApi = createApi({
  reducerPath: 'staticPagesApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${environment.BASE_URL}/static-pages`,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = (getState() as RootState).auth.tokens.accessToken;

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      headers.set('x-api-key', environment.API_KEY)

      return headers;
    },
  }),

  endpoints: (builder) => ({
    createStaticPage: builder.mutation<ICreatePageResponse, ICreatePageData>({
      query: (pageData) => {
        return {
          url: `/`,
          method: 'POST',
          body: pageData,
        };
      },
    }),

    updateStaticPageById: builder.mutation<
      IUpdatePageResponse,
      IUpdatePageData
    >({
      query: ({ pageId, pageData }) => {
        return {
          url: `/${pageId}`,
          method: 'PATCH',
          body: pageData,
        };
      },
    }),

    getAllStaticPagesPrivate: builder.query<
      IGetAllPagesPrivateResponse,
      IGetAllPagesParamsPrivate
    >({
      query: (params) => ({
        url: '',
        params,
      }),
    }),

    getStaticPageById: builder.query<
      IGetStaticPageById,
      string
    >({
      query: (pageId) => `/${pageId}`,
    }),

    deleteStaticPage: builder.mutation<ISimpleMessageResponse, string>({
      query: (staticPageId) => {
        return {
          url: `/${staticPageId}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useCreateStaticPageMutation,
  useUpdateStaticPageByIdMutation,
  useLazyGetAllStaticPagesPrivateQuery,
  useLazyGetStaticPageByIdQuery,
  useDeleteStaticPageMutation,
} = staticPagesApi;
