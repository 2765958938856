import React, { FC } from 'react';

//mui components
import { Menu, MenuItem, Typography } from '@mui/material';

//icons
import LogoutBoxLineIcon from 'remixicon-react/LogoutBoxLineIcon';

//styles
import styles from './styles.module.scss';

interface IMenuList {
  text: string;
  icon: React.ReactNode;
}
const menuList: IMenuList[] = [
  {
    text: 'Log Out',
    icon: <LogoutBoxLineIcon />,
  },
];

interface ProfileMenuProps {
  anchorEl: HTMLElement | null;
  closeMenu: () => void;
  onMenuItemClick: (menuItem: string) => void;
}

const ProfileMenu: FC<ProfileMenuProps> = ({
  anchorEl,
  closeMenu,
  onMenuItemClick,
}) => {
  return (
    <Menu
      className={styles.profileMenu}
      anchorEl={anchorEl}
      id="primary-search-account-menu"
      keepMounted
      open={Boolean(anchorEl)}
      onClose={closeMenu}
      disableScrollLock
    >
      {menuList.map(({ text, icon }, index) => (
        <MenuItem
          key={index}
          onClick={() => onMenuItemClick(text)}
          className={styles.profileMenu}
        >
          {icon}
          <Typography className={styles.menuText} variant="h6">
            {text}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default ProfileMenu;
