export enum SortBy {
  BY_TITLE = 'byTitle',
  BY_CREATED_AT = 'byCreatedAt',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ImageLabelTheme {
  DARK = 'Dark',
  LIGHT = 'Light'
}

export enum PostTypeEnum {
  BLOG = 'Blog',
  // NEWS = 'News',
  HOT_TECH = 'HotTech',
  SPOTLIGHT = 'Spotlight',
}

export type PostTypeProperty = 'Blog' | 'HotTech' | 'Spotlight';

export const postTypeProperty = ['Articles', 'HotTech', 'Spotlight'];
export const postLabelTheme = [ImageLabelTheme.LIGHT, ImageLabelTheme.DARK];
