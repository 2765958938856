import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../store/hooks';

export default function PrivateRoute({ redirectTo, children }) {
  const location = useLocation();

  const isAuth = useAuth();

  return isAuth ? (
    children
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
}
