//react
import React, { useEffect, useState } from 'react';

//Router
import { useNavigate, useParams } from 'react-router-dom';

//styles
import styles from './styles.module.scss';

//mui components
import { Box, Button, IconButton, Typography } from '@mui/material';

//icons
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';

//custom components
import PostDetails from '../../Components/PostDetails/PostDetails';

//redux
import { useLazyGetPostByIdQuery } from '../../store/posts/postsApi';
import { PostType } from '../../interfaces/post';

//notiflix
import { showErrorNotify } from '../../Components/helpers/showErrorNotify';

export const Post = () => {
  const [post, setPost] = useState<PostType | null>(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const [getPostByIdRequest, getPostByIdResponse] = useLazyGetPostByIdQuery();

  useEffect(() => {
    id && getPostByIdRequest(id);
  }, [id, getPostByIdRequest]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = getPostByIdResponse;
    if (data && isSuccess) {
      setPost(data.data.post);
    }
    showErrorNotify(isError, error);
  }, [getPostByIdResponse]);

  return (
    <Box position="relative" pb="68px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="32px"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton
              className={styles.arrowButton}
              onClick={() => navigate('/posts')}
            >
              <ArrowLeftLineIcon className={styles.arrowIcon} />
            </IconButton>
            <Typography className={styles.title}>Post View</Typography>
          </Box>
          <Button
            variant={'contained'}
            className={styles.button}
            onClick={() => navigate(`/post-edit/${id}`)}
          >
            Edit
          </Button>
        </Box>
      </Box>
      <PostDetails post={post} />
    </Box>
  );
};
