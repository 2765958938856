'use client';

// React
import { useEffect, useRef } from 'react';

// Redux
import { useAppDispatch, useAppSelector, useAuth } from '../store/hooks';
import { useRefreshMutation } from '../store/auth/authApi';
import authSelectors from '../store/auth/authSelectors';
import { logout, setRefreshData } from '../store/auth/authSlice';

// Environment
import environment from '../config';
import React from 'react';

export function RefreshProvider({ children }: { children: React.ReactNode }) {
  // Setup refresh token
  const dispatch = useAppDispatch();
  const isAuth = useAuth();
  const refreshInterval = useRef<NodeJS.Timer>();
  const refreshToken = useAppSelector(authSelectors.getRefreshToken);

  // RTK Query
  const [refreshRequest, { data, isError, isSuccess, error }] =
    useRefreshMutation();

  // Dispatch data
  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setRefreshData(data));
    }

    if (isError && error) {
      dispatch(logout());
    }
  }, [data, dispatch, error, isError, isSuccess]);

  useEffect(() => {
    if (isAuth) {
      refreshRequest({
        refreshToken,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  // Setup setInterval
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isAuth) {
        refreshRequest({
          refreshToken,
        });
      }
    }, (environment.REFRESH_TOKEN_MINUTES as number) * 60 * 1000); // 50 minutes in milliseconds
    refreshInterval.current = intervalId;

    return () => {
      clearInterval(refreshInterval.current as NodeJS.Timeout);
    };
  }, [isAuth, refreshRequest, refreshToken]);

  return <>{children}</>;
}
