// React
import React, { FormEvent, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// Mui
import {
  TextField,
  Typography,
  Box,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Icon
import CheckboxCircleLineIcon from '../../../assets/CheckboxCircleLineIcon.svg';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';

// Styles
import s from './styles.module.scss';

//redux
import { useResetPasswordUpdatePasswordMutation } from '../../../store/auth/authApi';

// Notification
import { showErrorNotify } from '../../helpers/showErrorNotify';
import { showSuccessNotify } from '../../helpers/showSuccessNotify';

const UpdatePasswordForm = () => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const { token } = useParams();

  // RTK queries - update password
  const [
    updatePasswordRequest,
    { data, isSuccess, isError, error, isLoading },
  ] = useResetPasswordUpdatePasswordMutation();

  useEffect(() => {
    if (data && isSuccess) {
      showSuccessNotify(data.message);
      setFormData({
        password: '',
        confirmPassword: '',
      });
    }

    showErrorNotify(isError, error);
  }, [data, isSuccess, error, isError]);

  // Handlers
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    updatePasswordRequest({ ...formData, token: token as string });
  };

  const handleClickShowPassword = () => setIsShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () =>
    setIsShowConfirmPassword(show => !show);

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      {isSuccess ? (
        <div className={s.container}>
          <img
            src={CheckboxCircleLineIcon}
            alt="CheckboxCircleLineIcon"
            className={s.successIcon}
          />
          <Box>
            <Typography className={s.successTitle} variant={'h1'}>
              Password Updated!
            </Typography>
            <Typography className={s.successContent}>
              Your password has been reset successfully. Use your new password
              to{' '}
              <Link className={s.successLink} to="/login">
                log in
              </Link>
              .
            </Typography>
          </Box>
        </div>
      ) : (
        <form className={s.form} onSubmit={e => handleSubmit(e)}>
          <Typography className={s.formTitle} variant={'h1'}>
            Create New Password
          </Typography>

          <Box sx={{ marginBottom: '30px' }}>
            <Typography className={s.label}>New Password</Typography>

            <TextField
              className={s.input}
              variant="outlined"
              type={isShowPassword ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{ color: 'var(--neutral-black)' }}
                    >
                      {isShowPassword ? <EyeOffLineIcon /> : <EyeLineIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Typography className={s.label}>Confirm Password</Typography>

            <TextField
              className={s.input}
              variant="outlined"
              type={isShowConfirmPassword ? 'text' : 'password'}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                      sx={{ color: 'var(--neutral-black)' }}
                    >
                      {isShowConfirmPassword ? (
                        <EyeOffLineIcon />
                      ) : (
                        <EyeLineIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <LoadingButton
            type="submit"
            startIcon={isLoading && <CircularProgress size={20} />}
            loadingPosition={isLoading ? 'start' : undefined}
            className={s.button}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            disabled={isLoading}
          >
            Submit
          </LoadingButton>
        </form>
      )}
    </>
  );
};

export default UpdatePasswordForm;
