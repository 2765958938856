//react
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DropResult,
} from 'react-beautiful-dnd';

// mui components
import { Box, Button, IconButton, Typography, Tab } from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { styled } from '@mui/material/styles';

// remixicon
import MoreLineIcon from 'remixicon-react/MoreLineIcon';
import AddLineIcon from 'remixicon-react/AddLineIcon';

//custom components
import PostCardMenu from '../../Components/PostCardMenu';
import PagesCategoryModal from '../../Components/Modals/PagesCategoryModal/PagesCategoryModal';
import { StaticPagesWorkspace } from '../../Components/StaticPageWorkspace/StaticPagesWorkspace';

//redux
import {
  useCreateNewCategoryMutation,
  useDeleteCategoryByIdMutation,
  useLazyGetAllCategoriesPrivateQuery,
  useUpdateCategoryByIdMutation,
} from '../../store/pagesCategory/pagesCategoryApi';
import {
  useDeleteStaticPageMutation,
  useLazyGetAllStaticPagesPrivateQuery,
} from '../../store/staticPages/staticPagesApi';

//notiflix
import { showSuccessNotify } from './../../Components/helpers/showSuccessNotify';
import { showErrorNotify } from '../../Components/helpers/showErrorNotify';

// constants
import { addCategoryConstants } from '../../Utils/constants';
import { PageType } from '../../interfaces/page';
import { SortBy, SortOrder } from '../../interfaces/requestParams';

//styles
import styles from './styles.module.scss';

interface TabListType {
  label: string;
  id: string;
  order: number;
  value: string;
}

interface CustomTabContainerProps {
  isActive: boolean;
}

const CustomTabContainer = styled('div')<CustomTabContainerProps>(
  ({ theme, isActive }) => ({
    padding: '12px 12px 14px',
    fontSize: '16px',
    fontWeight: 500,
    color: isActive ? theme.palette.primary.main : '#2d3748',
    backgroundColor: isActive ? '#fff' : 'transparent',
    borderRight: isActive ? '1px solid #edf2f7' : 'none',
    borderLeft: isActive ? '1px solid #edf2f7' : 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    textTransform: 'capitalize',
    cursor: 'pointer',
    position: 'relative',
    transition: 'background-color 250ms ease-in-out',
    height: '52px',
    boxSizing: 'border-box',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      height: '1.6px',
      backgroundColor: theme.palette.primary.main,
      transform: isActive ? 'scaleX(1)' : 'scaleX(0)',
      transition: 'transform 500ms ease-in-out',
      transformOrigin: 'left center',
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.selected,
    },
  })
);

interface CustomTabProps {
  tab: TabListType;
  isActive: boolean;
  onTabClick: (value: string) => void;
  handleClickSubmenu: (event: React.MouseEvent<HTMLElement>) => void;
  provided: DraggableProvided;
}

const CustomTab = ({
  tab,
  isActive,
  onTabClick,
  handleClickSubmenu,
  provided,
}: CustomTabProps) => (
  <CustomTabContainer
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    isActive={isActive}
    onClick={() => onTabClick(tab.value)}
  >
    <DragIndicatorIcon sx={{ fontSize: '16px' }} />
    {tab.label}
    {tab.value !== 'All Views' && (
      <IconButton
        sx={{
          width: '14px',
          height: '14px',
          padding: '2px',
          margin: '0 10px',
          visibility: isActive ? 'visible' : 'hidden',
          color: 'black',
        }}
        onClick={e => {
          e.stopPropagation();
          handleClickSubmenu(e);
        }}
      >
        <MoreLineIcon />
      </IconButton>
    )}
  </CustomTabContainer>
);

export const Pages = () => {
  const [tabList, setTabList] = useState<TabListType[]>([]);
  const [pagesList, setPagesList] = useState<PageType[]>([]);
  const [activeTab, setTab] = useState<string>('');
  const [sortCell, setSortCell] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<SortOrder>(SortOrder.ASC);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [categoryModal, setCategoryModal] = useState<boolean>(false);
  const [editCategoryModal, setEditCategoryModal] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState(false);
  const [updateSource, setUpdateSource] = useState('');

  const categoriesPage = 1;
  const categoriesLimit = 20;

  const navigate = useNavigate();
  const prevStateRef = useRef(tabList);

  // RTK Query
  const [getAllCategoriesRequest, getAllCategoriesData] =
    useLazyGetAllCategoriesPrivateQuery();

  const [createNewCategoryRequest, createNewCategoryData] =
    useCreateNewCategoryMutation();

  const [updateCategoryRequest, updateCategoryData] =
    useUpdateCategoryByIdMutation();

  const [deleteCategoryRequest, deleteCategoryData] =
    useDeleteCategoryByIdMutation();

  const [getAllStaticPagesRequest, getAllStaticPagesData] =
    useLazyGetAllStaticPagesPrivateQuery();

  const [
    deleteStaticPageRequest,
    {
      data: deleteStaticPageData,
      isLoading: isLoadingDeleteStaticPage,
      isSuccess: isSuccessDeleteStaticPage,
      isError: IsErrorDeleteStaticPage,
      error: errorDeleteStaticPage,
    },
  ] = useDeleteStaticPageMutation();

  //Handlers
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const findTabLabelByValue = () => {
    const tab = tabList.find(tab => tab.value === activeTab);
    return tab ? tab.label : undefined;
  };

  const findTabIdByValue = () => {
    const tab = tabList.find(tab => tab.value === activeTab);
    return tab ? tab.id : undefined;
  };

  const handleClickSubmenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleScrollMenu = () => {
    handleCloseMenu();
  };

  const handleGetAllCategories = () => {
    getAllCategoriesRequest({ page: 1, limit: 5 });
  };

  const handleCreateNewCategory = (title: string) => {
    if (tabList.length < addCategoryConstants.categoriesLimit) {
      // TODO: replace isActive when functionality will be connected
      createNewCategoryRequest({ title, isActive: true });
    }
  };

  const handleUpdateCategory = (title: string, fromModal = false) => {
    const categoryData = {
      title,
    };
    const categoryId = findTabIdByValue();

    if (categoryId) {
      setUpdateSource(fromModal ? 'modal' : 'reorder');
      updateCategoryRequest({ categoryData, categoryId });
    }
  };

  const handleDeleteCategory = () => {
    const categoryId = findTabIdByValue();
    categoryId && deleteCategoryRequest(categoryId);
  };

  const handleGetSortParam = (sortCell: string) => {
    switch (sortCell) {
      case 'Title':
        return SortBy.BY_TITLE;

      case 'Date Created':
        return SortBy.BY_CREATED_AT;

      default:
        return undefined;
    }
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (result: DropResult) => {
    setIsDragging(false);
    const { destination, source } = result;

    if (
      !destination ||
      destination.index === source.index ||
      destination.index === 0
    ) {
      return;
    }

    prevStateRef.current = [...tabList];

    const newTabList = Array.from(tabList);
    const [movedTab] = newTabList.splice(source.index, 1);
    newTabList.splice(destination.index, 0, movedTab);

    setTabList(newTabList);
    setTab(newTabList[destination.index].value);
    setUpdateSource('reorder');

    if (movedTab.id !== 'All Views') {
      updateCategoryRequest({
        categoryId: movedTab.id,
        categoryData: { order: destination.index - 1 },
      });
    }
  };

  const handleGetPagesDefault = () => {
    const category = findTabIdByValue();

    if (category === 'All Views') {
      getAllStaticPagesRequest({
        page: categoriesPage,
        limit: categoriesLimit,
      });
    } else {
      getAllStaticPagesRequest({
        page: categoriesPage,
        limit: categoriesLimit,
        category,
      });
    }
  };

  const handleDeletePage = async (pageId: string) => {
    await deleteStaticPageRequest(pageId);
  };

  //UseEffects
  useEffect(() => {
    if (anchorEl) {
      window.addEventListener('scroll', handleScrollMenu);
    } else {
      window.removeEventListener('scroll', handleScrollMenu);
    }
  }, [anchorEl]);

  useEffect(() => {
    handleGetAllCategories();
  }, []);

  useEffect(() => {
    const { data, isSuccess, isError, error } = getAllCategoriesData;
    if (data && isSuccess) {
      const categoriesTabList: TabListType[] = data.data.map(category => ({
        value: category.slug,
        label: category.title,
        id: category._id,
        order: category.order,
      }));

      // Prepending "All Views" which is static and always first
      const sortedTabList = [
        {
          label: 'All Views',
          value: 'All Views',
          id: 'All Views',
          order: -1,
        },
        ...categoriesTabList,
      ];

      setTabList(sortedTabList);
      setTab('All Views');
    } else {
      showErrorNotify(isError, error);
    }
  }, [getAllCategoriesData]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = createNewCategoryData;
    if (data && isSuccess) {
      showSuccessNotify('New category created successfully');
      handleGetAllCategories();
    }

    showErrorNotify(isError, error);
  }, [createNewCategoryData]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = updateCategoryData;
    if (data && isSuccess) {
      showSuccessNotify('Category successfully edited');
      if (updateSource === 'modal') {
        handleGetAllCategories();
      }
    }
    if (updateSource === 'reorder' && isError) {
      setTabList(prevStateRef.current);
    }

    showErrorNotify(isError, error);
  }, [updateCategoryData]);

  useEffect(() => {
    const { isSuccess, isError, error } = deleteCategoryData;
    if (isSuccess) {
      showSuccessNotify('Category successfully deleted');
      handleGetAllCategories();
    }

    showErrorNotify(isError, error);
  }, [deleteCategoryData]);

  useEffect(() => {
    handleGetPagesDefault();
  }, [activeTab]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = getAllStaticPagesData;
    if (data && isSuccess) {
      setPagesList(data.data.staticPages);
    }

    showErrorNotify(isError, error);
  }, [getAllStaticPagesData]);

  useEffect(() => {
    if (deleteStaticPageData && isSuccessDeleteStaticPage) {
      handleGetPagesDefault();
      showSuccessNotify('Page successfully deleted');
    }

    showErrorNotify(IsErrorDeleteStaticPage, errorDeleteStaticPage);
  }, [deleteStaticPageData, isSuccessDeleteStaticPage]);

  useEffect(() => {
    const category = findTabIdByValue();
    if (category === 'All Views') {
      sortCell &&
        getAllStaticPagesRequest({
          page: categoriesPage,
          limit: categoriesLimit,
          sortOrder: sortDirection,
          sortBy: handleGetSortParam(sortCell),
        });
    } else {
      sortCell &&
        getAllStaticPagesRequest({
          page: categoriesPage,
          limit: categoriesLimit,
          category,
          sortOrder: sortDirection,
          sortBy: handleGetSortParam(sortCell),
        });
    }
  }, [sortCell, sortDirection]);

  return (
    <Box pb="15px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="27px"
      >
        <Typography variant="h3">Pages</Typography>
        <Box>
          <Button
            variant="text"
            className={`${styles.button} ${styles.buttonCategory}`}
            onClick={() => setCategoryModal(true)}
            disabled={tabList.length >= addCategoryConstants.categoriesLimit}
          >
            Add category
          </Button>
          <Button
            variant="contained"
            className={`${styles.button} ${styles.buttonCreation}`}
            onClick={() => navigate('/page-creation')}
            disabled={!tabList.length}
          >
            Add page
          </Button>
        </Box>
      </Box>

      {tabList.length ? (
        <Box className={styles.content}>
          <TabContext value={activeTab}>
            <Box className={styles.tabListWrapper}>
              <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Droppable droppableId="1" direction="horizontal">
                  {props => (
                    <TabList
                      ref={props.innerRef}
                      {...props.droppableProps}
                      onChange={handleTabChange}
                      variant={'scrollable'}
                      visibleScrollbar
                      sx={{
                        display: 'flex',
                        '&.MuiTabs-root': {
                          minHeight: '52px',
                        },
                        '& .MuiTabs-scroller': {
                          height: '52px',
                        },
                        '& .MuiTabs-scroller .MuiTabs-indicator': {
                          backgroundColor: '#0065ff',
                          position: 'absolute',
                          top: '0px',
                          height: '1px',
                        },
                        '& .MuiTabs-flexContainer': {
                          overflowX: 'auto',
                          '-webkit-scrollbar-width': '3px',
                          '-webkit-scrollbar-color': `var(--border-color) var(--neutral-white)`,
                          '&::-webkit-scrollbar': {
                            height: '3px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: `var(--border-color)`,
                            borderRadius: '6px',
                          },
                          height: '52px',
                          gap: '12px',
                          alignItems: 'center',
                        },
                      }}
                    >
                      <Tab
                        sx={{
                          '&.MuiTab-root': {
                            color: '#2d3748',
                            textTransform: 'capitalize',
                            fontSize: '16px',
                            fontWeight: 500,
                            textWrap: 'nowrap',
                            '.MuiChip-root': {
                              background: '#EBEBEB',
                              color: '#A8A8A8',
                            },
                            minHeight: '52px',
                            width: 'fit-content',
                            padding: '14px 12px',
                          },
                          '&.Mui-selected': {
                            color: '#0065ff',
                            textTransform: 'capitalize',
                            fontSize: '16px',
                            fontWeight: 600,
                            textWrap: 'nowrap',
                            background: '#fff',
                            borderRight: '1px solid #edf2f7',
                            borderLeft: '1px solid #edf2f7',
                            '.MuiChip-root': {
                              background: '#636363',
                              color: '#fff',
                            },
                          },
                          cursor: 'default',
                        }}
                        label={'All Views'}
                        value={'All Views'}
                      />
                      {tabList.slice(1).map((tab, index) => (
                        <Draggable
                          key={tab.order}
                          draggableId={`${tab.order}`}
                          index={index + 1}
                          disableInteractiveElementBlocking={true}
                        >
                          {provided => (
                            <CustomTab
                              tab={tab}
                              isActive={activeTab === tab.value}
                              onTabClick={setTab}
                              handleClickSubmenu={handleClickSubmenu}
                              provided={provided}
                            />
                          )}
                        </Draggable>
                      ))}
                      <IconButton
                        sx={{
                          width: '16px',
                          height: '16px',
                          padding: '2px',
                          boxSizing: 'content-box',
                          visibility: isDragging ? 'hidden' : 'visible',
                        }}
                        onClick={() => setCategoryModal(true)}
                        disabled={
                          tabList.length >= addCategoryConstants.categoriesLimit
                        }
                      >
                        <AddLineIcon
                          style={{
                            fill:
                              tabList.length >=
                              addCategoryConstants.categoriesLimit
                                ? '#b3b9c4'
                                : '#0065ff',
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      </IconButton>
                    </TabList>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>

            {pagesList.length === 0 ? (
              <Box
                textAlign="center"
                alignContent="center"
                height="44px"
                bgcolor="#fff"
              >
                <Typography variant="subtitle2">
                  No pages in this category
                </Typography>
              </Box>
            ) : (
              tabList.map(tab => (
                <TabPanel
                  value={tab.value}
                  className={styles.tabWrapper}
                  key={tab.label}
                >
                  <Box className={styles.workspaceWrapper}>
                    {pagesList.length === 0 ? (
                      <Box
                        textAlign="center"
                        alignContent="center"
                        height="44px"
                        bgcolor="#fff"
                      >
                        <Typography variant="subtitle2">
                          No pages in this category
                        </Typography>
                      </Box>
                    ) : (
                      <StaticPagesWorkspace
                        initialItems={pagesList}
                        disableDrag={tab.value === 'All Views'}
                        onDeletePage={handleDeletePage}
                        isLoadingDeleteStaticPage={isLoadingDeleteStaticPage}
                        setSortCell={setSortCell}
                        setSortDirection={setSortDirection}
                        sortCell={sortCell}
                        sortDirection={sortDirection}
                      />
                    )}
                  </Box>
                </TabPanel>
              ))
            )}
          </TabContext>
        </Box>
      ) : (
        <Box textAlign="center">
          <Typography variant="subtitle2">No categories added</Typography>
        </Box>
      )}

      <PagesCategoryModal
        open={editCategoryModal || categoryModal}
        onClose={() => {
          setCategoryModal(false);
          setEditCategoryModal(false);
        }}
        categoryValue={editCategoryModal ? findTabLabelByValue() : ''}
        onSubmit={title => {
          if (editCategoryModal) {
            handleUpdateCategory(title, true);
          } else {
            handleCreateNewCategory(title);
          }
        }}
      />

      <PostCardMenu
        anchorEl={anchorEl}
        handleClose={handleCloseMenu}
        handleDelete={handleDeleteCategory}
        handleEdit={() => setEditCategoryModal(true)}
      />
    </Box>
  );
};
