import React, { FC } from 'react';

// Formik
import { Field, FieldProps, FormikErrors, FormikTouched, FormikValues, getIn } from 'formik';

// styles
import styles from './styles.module.scss';

const ErrorMessage: FC<{ name: string }> = ({ name }) => (
    <Field name={name}>
        {({ field, form }: FieldProps<FormikValues>) => {
            const error = getIn(form.errors as FormikErrors<FormikValues>, name);
            const touch = getIn(form.touched as FormikTouched<FormikValues>, name);
            return touch && error ? <div className={styles.errorMessage} style={{ minHeight: 'auto' }}>{error}</div> : null;
        }}
    </Field>
);

export default ErrorMessage;
