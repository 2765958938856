// Redux
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist/es/constants';

// Reducer
import authSlice from './auth/authSlice';
import userSlice from './user/userSlice';

// API configuration
import { authApi } from './auth/authApi';
import { userApi } from './user/userApi';
import { staticPagesApi } from './staticPages/staticPagesApi';
import { pagesCategoryApi } from './pagesCategory/pagesCategoryApi';
import { postsApi } from './posts/postsApi';
import { metricsApi } from './metrics/metricsApi';

export const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [staticPagesApi.reducerPath]: staticPagesApi.reducer,
  [pagesCategoryApi.reducerPath]: pagesCategoryApi.reducer,
  [postsApi.reducerPath]: postsApi.reducer,
  [metricsApi.reducerPath]: metricsApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    authApi.reducerPath,
    userApi.reducerPath,
    staticPagesApi.reducerPath,
    pagesCategoryApi.reducerPath,
    postsApi.reducerPath,
    metricsApi.reducerPath,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authApi.middleware,
      userApi.middleware,
      staticPagesApi.middleware,
      pagesCategoryApi.middleware,
      postsApi.middleware,
      metricsApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);

export default store;
