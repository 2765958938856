// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Environment
import environment from '../../config';

// Types
import { RootState } from '../store';
import {
  ILoginData,
  ILoginResponse,
  IRefreshData,
  IRefreshResponse,
  IResetPasswordSendEmailData,
  IResetPasswordSendEmailResponse,
  IResetPasswordUpdatePasswordData,
  IResetPasswordUpdatePasswordResponse,
} from './authTypes';

export const authApi = createApi({
  reducerPath: 'authApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${environment.BASE_URL}/auth`,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = (getState() as RootState).auth.tokens.accessToken;

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      headers.set('Content-Type', 'application/json');

      headers.set('x-api-key', environment.API_KEY);

      return headers;
    },
  }),

  endpoints: builder => ({
    login: builder.mutation<ILoginResponse, ILoginData>({
      query: credentials => ({
        url: 'login',
        method: 'POST',
        body: credentials,
      }),
    }),

    refresh: builder.mutation<IRefreshResponse, IRefreshData>({
      query: credentials => ({
        url: 'refresh',
        method: 'PATCH',
        body: credentials,
      }),
    }),

    resetPasswordSendEmail: builder.mutation<
      IResetPasswordSendEmailResponse,
      IResetPasswordSendEmailData
    >({
      query: data => ({
        url: 'reset',
        method: 'POST',
        body: data,
      }),
    }),

    resetPasswordUpdatePassword: builder.mutation<
      IResetPasswordUpdatePasswordResponse,
      IResetPasswordUpdatePasswordData
    >({
      query: ({ password, confirmPassword, token }) => ({
        url: `reset/${token}`,
        method: 'POST',
        body: { password, confirmPassword },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRefreshMutation,
  useResetPasswordSendEmailMutation,
  useResetPasswordUpdatePasswordMutation,
} = authApi;
