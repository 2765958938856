// React
import React, { useCallback, useEffect, useState } from 'react';

// MUI
import { Box, Button, Typography } from '@mui/material';

// Styles
import styles from './styles.module.scss';

// Custom components
import Workspace from '../../Components/Workspace/Workspace';
import UserCard from '../../Components/UserCard/UserCard';
import PaginationPanel from '../../Components/PaginationPanel/PaginationPanel';
import UserCreateModal from '../../Components/Modals/UserCreateModal/UserCreateModal';

//redux
import { useLazyGetAllUsersQuery } from '../../store/user/userApi';

// Helpers
import { calculateTotalPages } from '../../helpers';

//notiflix
import { showErrorNotify } from '../../Components/helpers/showErrorNotify';

// constants
import { tableRows } from './constants';
import { IUser } from '../../store/user/userTypes';

export const Users = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [page, setPage] = useState<number>(1);
  const [isCreateUserModalOpen, setCreateUserModalOpen] =
    useState<boolean>(false);

  const [sortCell, setSortCell] = useState<string | null>(null);

  const limit = 10;

  // RTK Query - Get All Users
  const [getAllUsersRequest, getAllUsersData] = useLazyGetAllUsersQuery();

  useEffect(() => {
    getAllUsersRequest({ page, limit });
  }, [page]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = getAllUsersData;
    if (data && isSuccess) {
      setUsers(data.data.users);
    }

    showErrorNotify(isError, error);
  }, [getAllUsersData]);

  const handleGetAllUsers = useCallback(
    () => getAllUsersRequest({ page, limit }),
    [page]
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        pb="10px"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="27px"
        >
          <Typography variant="h1" className={styles.title}>
            Users
          </Typography>

          <Button
            variant="contained"
            className={styles.buttonCreation}
            onClick={() => setCreateUserModalOpen(true)}
          >
            Add New User
          </Button>
        </Box>

        <Box className={styles.content}>
          {getAllUsersData.data && users.length === 0 ? (
            <Typography className={styles.noContentText}>
              No users found
            </Typography>
          ) : (
            <Box className={styles.workspaceWrapper}>
              <Workspace
                items={users}
                rows={tableRows}
                setSortCell={setSortCell}
                setSortDirection={() => {}}
                sortCell={sortCell}
              >
                {users.map(user => (
                  <UserCard
                    key={user._id}
                    user={user}
                    handleGetAllUsers={handleGetAllUsers}
                  />
                ))}
              </Workspace>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            {!!getAllUsersData.data?.totalUsers && (
              <PaginationPanel
                currentPage={page}
                setPage={setPage}
                totalPages={calculateTotalPages({
                  limitPerPage: limit,
                  totalItems: getAllUsersData.data?.totalUsers,
                })}
              />
            )}
          </Box>
        </Box>
      </Box>
      <UserCreateModal
        isOpen={isCreateUserModalOpen}
        onClose={() => setCreateUserModalOpen(false)}
        handleGetAllUsers={handleGetAllUsers}
      />
    </>
  );
};
