// React
import React, { FormEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Mui
import {
  TextField,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';

// Icon
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';

// Styles
import s from './styles.module.scss';

//redux
import { useLoginMutation } from '../../../store/auth/authApi';
import { useAppDispatch } from '../../../store/hooks';
import { setLoginData } from '../../../store/auth/authSlice';

// Notification
import { showErrorNotify } from '../../helpers/showErrorNotify';
import { showSuccessNotify } from '../../helpers/showSuccessNotify';
import { LoadingButton } from '@mui/lab';

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useAppDispatch();

  const [loginRequest, { data, isSuccess, isError, error, isLoading }] =
    useLoginMutation();

  // Handlers
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    loginRequest(formData);
  };

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(setLoginData(data));
      showSuccessNotify('Welcome to CMS!');
    }

    showErrorNotify(isError, error);
  }, [data, isSuccess, error, isError]);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <form className={s.form} onSubmit={e => handleSubmit(e)}>
      <Typography className={s.formTitle} variant={'h1'}>
        Login
      </Typography>
      <Box className={s.inputsWrapper}>
        <Box>
          <Typography className={s.label}>Email</Typography>
          <TextField
            className={s.input}
            variant="outlined"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
          />
        </Box>

        <Box>
          <Typography className={s.label}>Password</Typography>

          <TextField
            className={s.input}
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ color: 'var(--neutral-black)' }}
                  >
                    {showPassword ? <EyeOffLineIcon /> : <EyeLineIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Link className={s.formLink} to={'/reset-password-send-email'}>
        Forgot password?
      </Link>

      <LoadingButton
        type="submit"
        startIcon={isLoading && <CircularProgress size={20} />}
        loadingPosition={isLoading ? 'start' : undefined}
        className={s.button}
        variant="contained"
        color="primary"
        fullWidth
        disableElevation
        disabled={isLoading}
      >
        Sign In
      </LoadingButton>
    </form>
  );
};

export default LoginForm;
