import React, { FC, useEffect } from 'react';

// Redux
import { useDeleteUserByIdMutation } from '../../../store/user/userApi';

// Components
import ModalWrapper from '../ModalWrapper/ModalWrapper';

// MUI
import { Box, Button, CircularProgress, Typography } from '@mui/material';

// Notification
import { showSuccessNotify } from '../../../Components/helpers/showSuccessNotify';
import { showErrorNotify } from '../../../Components/helpers/showErrorNotify';

//styles
import s from './styles.module.scss';

// Types
import { IUser } from '../../../store/user/userTypes';
import { LoadingButton } from '@mui/lab';

interface IUserDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: IUser;
  handleGetAllUsers: () => void;
}

const UserDeleteModal: FC<IUserDeleteModalProps> = ({
  user,
  isOpen,
  onClose,
  handleGetAllUsers,
}) => {
  // RTK QUERY - Delete user
  const [deleteUserByIdRequest, deleteUserByIdData] =
    useDeleteUserByIdMutation();
  const { isLoading } = deleteUserByIdData;

  useEffect(() => {
    const { isSuccess, isError, error } = deleteUserByIdData;
    if (isSuccess) {
      showSuccessNotify('User successfully deleted');
      handleGetAllUsers();
      handleClose();
    }

    showErrorNotify(isError, error);
  }, [deleteUserByIdData]);

  // Handlers
  const handleClose = () => {
    onClose();
  };

  const handleDeleteUser = () => {
    deleteUserByIdRequest(user._id);
  };

  return (
    <ModalWrapper open={isOpen} onClose={handleClose} title="Delete User">
      <Typography className={s.info}>
        {`Please confirm if you want to permanently remove the account for ${user.first_name} ${user.last_name}`}
      </Typography>
      <Typography className={s.desc}>
        This action is irreversible and will delete all associated user data.
      </Typography>
      <Box className={s.buttonsWrapper}>
        <Button className={s.cancelButton} onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          startIcon={isLoading && <CircularProgress size={20} />}
          loadingPosition={isLoading ? 'start' : undefined}
          className={s.deleteButton}
          onClick={handleDeleteUser}
          variant="contained"
          color="primary"
          fullWidth
          disableElevation
          disabled={isLoading}
        >
          Delete
        </LoadingButton>
      </Box>
    </ModalWrapper>
  );
};

export default UserDeleteModal;
