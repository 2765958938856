import { useEffect } from 'react';

export const useLockBodyScroll = (isOpen: boolean): void => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(
      document.documentElement
    ).overflow;

    document.documentElement.style.overflow = isOpen ? 'hidden' : originalStyle;

    return () => {
      document.documentElement.style.overflow = originalStyle;
    };
  }, [isOpen]);
};
