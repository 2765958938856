import React, { FC } from 'react';

//mui components
import {
  Button,
  Grid,
  Modal,
  Typography,
  IconButton,
  Box,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

//styles
import styles from './styles.module.scss';

// remixicon
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

interface DeletePostModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  isLoadingDeletePost: boolean;
}

const DeletePostModal: FC<DeletePostModalProps> = ({
  open,
  onClose,
  onDelete,
  isLoadingDeletePost,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ backdropFilter: 'blur(10px)' }}
    >
      <Grid className={styles.modal}>
        <Typography className={styles.modalTitle} variant="h6">
          {`Please, confirm if you want to permanently remove the post. This action is irreversible.`}
        </Typography>
        <Box
          display={'flex'}
          justifyContent={'center'}
          gap={'10px'}
          width={'100%'}
        >
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            className={`${styles.button} ${styles.buttonCancel}`}
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            startIcon={
              isLoadingDeletePost && (
                <CircularProgress
                  sx={{ color: 'var(--neutral-white)' }}
                  size={'16px'}
                />
              )
            }
            loadingPosition={isLoadingDeletePost ? 'start' : undefined}
            className={styles.button}
            onClick={onDelete}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            disabled={isLoadingDeletePost}
          >
            Delete
          </LoadingButton>
        </Box>

        <IconButton className={styles.buttonClose} onClick={onClose}>
          <CloseLineIcon />
        </IconButton>
      </Grid>
    </Modal>
  );
};

export default DeletePostModal;
