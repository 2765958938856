// Components
import ResetPasswordSendEmailForm from '../../../Components/ResetPassword/ResetPasswordSendEmailForm/ResetPasswordSendEmailForm';

// Styles
import s from './styles.module.scss';

export const ResetPasswordSendEmailPage = () => {
  return (
    <div className={s.formWrapper}>
      <ResetPasswordSendEmailForm />
    </div>
  );
};
