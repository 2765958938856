//react
import React, { FC, useEffect, useState } from 'react';

//Router
import { useNavigate, useParams } from 'react-router-dom';

//styles
import styles from './styles.module.scss';

//mui components
import { Box, IconButton, Typography } from '@mui/material';

//icons
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';

//custom components
import PostForm from '../../Components/PostForm/PostForm';

//redux
import {
  useCreatePostMutation,
  useLazyGetPostByIdQuery,
  useUpdatePostByIdMutation,
} from '../../store/posts/postsApi';
import { PostType } from '../../interfaces/post';

//notiflix
import { showErrorNotify } from '../../Components/helpers/showErrorNotify';
import { showSuccessNotify } from '../../Components/helpers/showSuccessNotify';

interface AddPostProps {
  isEdit?: boolean;
}

export const AddPost: FC<AddPostProps> = ({ isEdit = false }) => {
  const [post, setPost] = useState<PostType | null>(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const [getPostByIdRequest, getPostByIdResponse] = useLazyGetPostByIdQuery();
  const [createPostRequest, createPostResponse] = useCreatePostMutation();
  const [updatePostRequest, updatePostResponse] = useUpdatePostByIdMutation();

  const handleSubmit = (postData: FormData) => {
    if (isEdit && id) {
      updatePostRequest({ postData, postId: id });
    } else {
      createPostRequest(postData);
    }
  };

  useEffect(() => {
    isEdit && id && getPostByIdRequest(id);
  }, [isEdit, id, getPostByIdRequest]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = getPostByIdResponse;
    if (data && isSuccess) {
      setPost(data.data.post);
    }
    showErrorNotify(isError, error);
  }, [getPostByIdResponse]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = createPostResponse;
    if (data && isSuccess) {
      showSuccessNotify('Post created successfully');
      navigate('/posts');
    }
    showErrorNotify(isError, error);
  }, [createPostResponse]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = updatePostResponse;
    if (data && isSuccess) {
      showSuccessNotify('Post updated successfully');
      navigate('/posts');
    }
    showErrorNotify(isError, error);
  }, [updatePostResponse]);

  return (
    <Box position="relative" pb="68px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="32px"
      >
        <Box display="flex" alignItems="center">
          <IconButton
            className={styles.arrowButton}
            onClick={() => navigate('/posts')}
          >
            <ArrowLeftLineIcon className={styles.arrowIcon} />
          </IconButton>
          <Typography variant="h3" display="flex">
            {isEdit ? 'Posts' : 'Add Post'}
          </Typography>
        </Box>
      </Box>
      <PostForm
        isEdit={isEdit}
        post={post}
        onSubmit={handleSubmit}
        isLoading={createPostResponse.isLoading || updatePostResponse.isLoading}
      />
    </Box>
  );
};
