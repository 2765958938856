//react
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//styles
import styles from './styles.module.scss';

import 'react-quill/dist/quill.snow.css';

//mui components
import {
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';

//custom components
import ErrorMessage from '../common/ErrorMessage';
import CustomSwitchInput from '../CustomSwitchInput/CustomSwitchInput';
import ConfirmDeclineButtons from './ConfirmDeclineButtons/ConfirmDeclineButtons';
import QuillEditor from '../QuillEditor/QuillEditor';

//formik
import {
  Field,
  FieldInputProps,
  Form,
  Formik,
  FormikErrors,
  FormikProps,
  FormikTouched,
  FormikValues,
} from 'formik';
import PagesFormSchema from './PagesFormSchema';

//types
import { PageType } from '../../interfaces/page';

//redux
import { ICreatePageData } from '../../store/staticPages/staticPagesTypes';
import { useLazyGetAllCategoriesPrivateQuery } from '../../store/pagesCategory/pagesCategoryApi';

//helpers
import { showErrorNotify } from '../helpers/showErrorNotify';

interface CategoryOptionsType {
  label: string;
  id: string;
}

const initialValues: ICreatePageData = {
  title: '',
  content: '',
  category: '',
  isActive: false,
};

interface PagesFormProps {
  isEdit: boolean;
  page: PageType | null;
  onSubmit: (params: ICreatePageData) => void;
  isLoading: boolean;
}

const PagesForm: FC<PagesFormProps> = ({
  isEdit,
  page,
  onSubmit,
  isLoading,
}) => {
  const [pageData, setPageData] = useState<ICreatePageData>(initialValues);
  const [categoryOptions, setCategoryOptions] = useState<CategoryOptionsType[]>(
    []
  );
  const formikRef = useRef<FormikProps<ICreatePageData> | null>(null);
  const navigate = useNavigate();

  const [getAllCategoriesRequest, getAllCategoriesData] =
    useLazyGetAllCategoriesPrivateQuery();

  const handleAutocompleteChange = (
    field: FieldInputProps<string>,
    value: string
  ) => {
    field.onChange({
      target: {
        name: field.name,
        value,
      },
    });
    setPageData({ ...pageData, [field.name]: value });
  };

  const handleSubmit = (values: ICreatePageData) => {
    const category = categoryOptions.find(
      category => category.label === values.category
    );

    if (category) {
      const pageData = { ...values, category: category.id };
      onSubmit(pageData);
    }
  };

  useEffect(() => {
    if (page && isEdit) {
      setPageData({
        title: page.title,
        content: page.content || '',
        category: page.category.title,
        isActive: page.isActive,
      });

      if (formikRef.current) {
        formikRef.current.setFieldValue('title', page.title);
        formikRef.current.setFieldValue('content', page.content);
        formikRef.current.setFieldValue('category', page.category.title);
        formikRef.current.setFieldValue('isActive', page.isActive);
      }
    }
  }, [page, isEdit]);

  useEffect(() => {
    getAllCategoriesRequest({ page: 1, limit: 5 });
  }, [getAllCategoriesRequest]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = getAllCategoriesData;
    if (data && isSuccess) {
      const categoriesTabList: CategoryOptionsType[] = [];
      data.data.forEach(category => {
        categoriesTabList.push({
          label: category.title,
          id: category._id,
        });
      });

      setCategoryOptions(categoriesTabList);
    }

    showErrorNotify(isError, error);
  }, [getAllCategoriesData]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={PagesFormSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({
          errors,
          touched,
          setFieldValue,
        }: {
          errors: FormikErrors<FormikValues>;
          touched: FormikTouched<FormikValues>;
          setFieldValue: any;
        }) => (
          <Form>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              position="relative"
              mb="40px"
              mt="31px"
            >
              <Box width="100%" display="flex" gap="15px">
                <Box position="relative" width="100%">
                  <InputLabel className={styles.label}>
                    Title <span className={styles.labelRequired}>*</span>
                  </InputLabel>
                  <Field
                    as={OutlinedInput}
                    name="title"
                    fullWidth
                    value={pageData.title}
                    className={styles.input}
                    sx={{ height: { xs: '48px', md: '40px', xl: '48px' } }}
                    onInput={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => setPageData({ ...pageData, title: e.target.value })}
                  />
                  <ErrorMessage name="title" />
                </Box>

                <Box position="relative" width="100%">
                  <InputLabel className={styles.label}>
                    Category <span className={styles.labelRequired}>*</span>
                  </InputLabel>

                  <Field name="category" value={pageData.category}>
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <Autocomplete
                        fullWidth
                        value={pageData.category}
                        className={styles.select}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            height: '48px',
                            paddingLeft: '14px',
                          },
                          '& .MuiAutocomplete-endAdornment': {
                            zIndex: 1,
                          },
                        }}
                        disableClearable
                        options={categoryOptions.map(
                          category => category.label
                        )}
                        renderOption={(props, option) => (
                          <Typography {...props} key={option}>
                            {option}
                          </Typography>
                        )}
                        renderInput={params => <TextField {...params} />}
                        onInputChange={(_, newValue) => {
                          handleAutocompleteChange(field, newValue as any);
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="category" />
                </Box>
              </Box>

              <CustomSwitchInput
                checked={pageData.isActive}
                onChange={param => {
                  setPageData({ ...pageData, isActive: param });
                  setFieldValue('isActive', param);
                }}
                label="Active"
              />
            </Box>
            <Box position="relative">
              <InputLabel className={styles.label}>
                Content <span className={styles.labelRequired}>*</span>
              </InputLabel>
              <QuillEditor
                value={
                  pageData.content
                }
                readOnly={false}
                placeholder="Create your page content"
                setFieldValue={value => {
                  setPageData({ ...pageData, content: value });
                  setFieldValue('content', value);
                }}
              />

              <ErrorMessage name="content" />
            </Box>

            <Box position="absolute" top="-2px" right="0">
              <ConfirmDeclineButtons
                handleCancel={() => navigate('/pages')}
                saveButtonText={isEdit ? 'Save' : 'Create'}
                isLoading={isLoading}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PagesForm;
